<template>
  <b-col class="text-center">
    <span>
      xVisor {{ xvisorVersion }} &copy; {{ new Date().getFullYear() }}
      <b-link href="https://ennetix.com" target="_blank">
        Ennetix, Inc.
      </b-link>
      All rights reserved.
    </span>
  </b-col>
</template>

<script>
export default {
  data() {
    return {
      xvisorVersion: '',
    };
  },
  mounted() {
    this.$http
      .get('/version.json')
      .then((result) => { this.xvisorVersion = result.data; });
  },
};
</script>
