<template>
  <layout-vertical>

    <router-view />

    <template #navbar="{ toggleVerticalMenuActive }">
      <navbar :toggle-vertical-menu-active="toggleVerticalMenuActive" />
    </template>
    <template #footer>
      <XvisorVersion></XvisorVersion>
    </template>

  </layout-vertical>
</template>

<script>
import LayoutVertical from '@core/layouts/layout-vertical/LayoutVertical.vue';
import Navbar from '@/layouts/components/Navbar.vue';
import XvisorVersion from '@/xvisor/components/XvisorVersion.vue';

export default {
  components: {
    LayoutVertical,
    Navbar,
    XvisorVersion,
  },
};
</script>

<style lang="scss">
// Shifts up the sidebar icons.
.main-menu ul {
  margin: -5px 0 0 0;
};

// Keeps the expanded logo the same positioning as when collapsed.
.main-menu .navbar-header {
  .navbar-brand {
    margin-top: 1rem;
  }
  padding: 0rem 1rem 0rem 0.5rem;
};

// Centers the Xvisor logo and decreases width of left sidebar.
.vertical-layout.vertical-menu-modern.menu-collapsed .main-menu {
  .navbar-header{
    padding: 0rem 1rem 0rem 0.5rem;
  };
  width: 50px;
};

// Provides each left-sidebar icon with appropriate spacing/margins
// due to the smaller width.
.main-menu.menu-light .navigation>li>a {
  margin: 5px;
  padding: 0.5rem 0.8rem;
};
</style>
